import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LoanCalculator from "../components/loanCalculator/loanCalculator"
import UniclickForm from "../components/uniclickForm/uniclickForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions";

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "<p>El financiamiento ofrecido en este sitio web es proveído  por UNIFIN SOFOM la cual se encuentra regulada y supervisada por la CNBV y la CONDUSEF. EVVA Plataforma, S.A.P.I. de C.V. es el intermediario tecnológico entre el usuario del sitio web y la institución financiera, por lo que no realiza actividades financieras o actividades que se encuentre reguladas o supervisadas por autoridad alguna.</p>";
const contentMaxWidth = "980px";

const Financiamiento = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const uniclickFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <UniclickForm lang={appState.lang} title="¡Impulsa tu negocio ya 🚀!" subtitle="Por ahora el acceso a nuestro financiamiento es solo por invitación. Regístrate y solicita tu interés cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpUniclickCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const rangeHandler = (e) => {
        const {name, value} = e.target;

        if (name === "amount") {
            dispatch(ACTIONS.setLoanValue(value))
        }

        if (name === "timeLimit") {
            dispatch(ACTIONS.setLoanTimeLimit(value))
        }
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="financiamiento" title="Crédito Pyme y Préstamos para Negocios | EVVA - Uniclick" description="Con el crédito EVVA Uniclick aplica en 2 sencillos pasos. Obtén una decisión de crédito rápida. Si se aprueba, los fondos se transfieren en no más de 24 horas. Devuélvenos en el transcurso del plan de 3-12 meses."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title="Lleva tu negocio al siguiente nivel" description="<h3>Expande, fondea tu siguiente reto, crece tu equipo o simplemente obtén un impulso de efectivo con nuestro financiamiento que llega en minutos.</h3>" position="left" image="hero-financing.png" actionLabel="Solicita tu crédito" action={uniclickFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpUniclick">
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-120px", zIndex: "1"}}>
                    <Card subtitle="Hacemos esto posible gracias a Uniclick" description="<p>Hemos unido fuerzas con Uniclick by Unifin, líder en el mercado de financiamiento que ha impulsado a miles de PyMEs en México.</p>" descriptionColor="#363636" image="logo-uniclick.svg" imageWidth="260px" bgColor="#fff" horizontal padding shadow/>
                </div>
            </PageSection>
            <PageSection maxColumns="4" title="Nuestra misión es ayudarte a crecer" >
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="search-icon.svg"
                    description="<p>Créditos simples desde <br />200 mil a 2.5 MDP</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="decision-time-icon.svg"
                    description="<p>Obtén una decisión en minutos y sin complicaciones</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="total-flexibility-icon.svg"
                    description="<p>Flexibilidad total, <br />plazo entre 3 y 12 meses</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="no-extra-fees-icon.svg"
                    description="<p>Sin letras chiquitas ni penalidades por anticipos</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
            </PageSection>
            <PageSection maxColumns="4" maxWidth={contentMaxWidth} title="¿Qué necesito para obtener mi crédito?" bgColor="#F9F9F9" >
                <Card
                    imageHeight="100px"
                    image="limited-company-icon.svg"
                    description="<p>Persona moral o Persona Física con Actividad Empresarial</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="timer-icon.svg"
                    description="<p>Tener por lo menos <br />12 meses de operación</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="sat-icon.svg"
                    description="<p>Estar debidamente registrado en el SAT</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="signature-icon.svg"
                    description="<p>Contar con tu e.Firma para firma de contrato</p>"
                    textAlign="center"
                />
            </PageSection>
            <PageSection title="¿Cómo funciona?" maxWidth={contentMaxWidth}>
                <Card
                    title="Registra tu solicitud con EVVA"
                    description="<p>Registra tu datos en nuestra plataforma. Un ejecutivo te contactará para hacer tu solicitud en línea.</p>"
                    descriptionColor="#363636"
                    image="register-uniclick.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Firma tu contrato Uniclick"
                    description="<p>Entrega documentación adicional y firma tu contrato.</p>"
                    descriptionColor="#363636"
                    image="sign-uniclick.png"
                    imageWidth="420px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Listo! Impulsa tu negocio ya"
                    description="<p>Una vez autorizado tu negocio, podrás recibir tu crédito en tu cuenta EVVA o en la cuenta de tu preferencia en no más de 24 horas.</p>"
                    descriptionColor="#363636"
                    image="grow-uniclick.png"
                    imageWidth="420px"
                    imageHeight="290px"
                    marginTopContent="40px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Estima el impulso que requieres para tu negocio" description="Cálculos con base a los términos y condiciones de Uniclick" descriptionImg="../logo-uniclick.svg" altDescImg="Uniclick - Crédito Empresarial">
                <LoanCalculator lang={appState.lang} loanValue={appState.loanValue} loanTimeLimit={appState.loanTimeLimit} onChange={rangeHandler} />
            </PageSection>
            <PageSection maxColumns={3} title="Descubre EVVA marketplace" description="Tenemos todos los servicios digital que hacer más sencilla tu vida financiera de cada día.">
                <Card
                    subtitle="Cuenta empresarial"
                    image="logo.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/"
                    linkLabel="Conoce más"
                    imageShadow
                />
                <Card
                    subtitle="EVVA lealtad digital"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/wallet"
                    linkLabel="Conoce más"
                    imageShadow
                />
                <Card
                    subtitle="Tu información SAT"
                    image="logo-sat-white.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/impuestos-sat"
                    linkLabel="Conoce más"
                    imageShadow
                />
            </PageSection>
            <SecondarySection
                title="Con Uniclick y EVVA crecemos"
                shortDescription="Con financiamiento Uniclick y tu cuenta EVVA, puedes concentrate en tu negocio, mientras nosotros nos enfocamos en tus finanzas. Llevar tu negocio al siguiente nivel nunca ha sido tan fácil."
                position="center"
                image="evva-together.jpg"
                actionLabel="Obtén tu crédito ya"
                action={uniclickFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpUniclick"
            />
        </Layout>
    )
};

export default Financiamiento
